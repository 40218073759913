import {BI_ORIGINS, PAST_EVENT_STATUSES, UPCOMING_EVENT_STATUSES, getEventId} from '@wix/wix-events-commons-statics'
import {callAPI, createActions} from '../../../commons/actions/data-action-helper'
import {EventsList, Tabs} from '../../../commons/enums'
import {getFullLocale} from '../../../commons/selectors/environment'
import {getEventUrl} from '../../../commons/selectors/event'
import {openModal} from '../../../commons/services/modal'
import {getMemberId, isOwnProfile} from '../selectors/user'
import {DetailedEvent, GetState, StoreExtraArgs} from '../types/state'
import {isTicketedWithNoOrder} from '../selectors/event'
import {setActiveElement} from '../../../commons/actions/focus-handler'
import {getMyTickets} from './tickets'

export const TOGGLE_EVENT_DETAILS = 'TOGGLE_EVENT_DETAILS'
export const CLOSE_ALL_EVENTS = 'CLOSE_ALL_EVENTS'
export const GET_EVENTS = createActions('GET_EVENTS')
export const SET_TAB = 'SET_TAB'
export const SEE_OTHER_EVENTS = 'SEE_OTHER_EVENTS'
export const SHARE_EVENT_CLICKED = 'SHARE_EVENT_CLICKED'

export const getEvents = (tab: Tabs, locale?: string) => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const isUpcoming = tab === Tabs.UPCOMING
  const statuses = isUpcoming ? UPCOMING_EVENT_STATUSES : PAST_EVENT_STATUSES
  const offset = state.events[isUpcoming ? EventsList.UPCOMING : EventsList.PAST].length
  const memberId = getMemberId(state)
  locale = locale || getFullLocale(state)

  if (memberId) {
    return dispatch(
      callAPI(GET_EVENTS, {
        memberId,
        statuses,
        extended: isOwnProfile(state),
        offset,
        tab,
        locale,
      }),
    )
  }
}

export const toggleEventDetails = (event: DetailedEvent) => (dispatch: Function, getState: GetState) => {
  const eventId = getEventId(event.event)
  const eventTickets = getState().tickets[eventId]

  if (isTicketedWithNoOrder(event) && !eventTickets) {
    dispatch(getMyTickets(eventId))
  }

  dispatch({
    type: TOGGLE_EVENT_DETAILS,
    payload: eventId,
  })
}

export const closeAllEvents = () => ({
  type: CLOSE_ALL_EVENTS,
})

export const setTab = (tab: Tabs) => ({
  type: SET_TAB,
  payload: tab,
})

interface ShareEventArgs {
  event: DetailedEvent
  returnFocusElement?: string
}
export const shareEvent = ({event: {event}, returnFocusElement}: ShareEventArgs) => async (
  dispatch: Function,
  getState: GetState,
  {pageUrl}: StoreExtraArgs,
) => {
  const eventId = event.id
  const eventUrl = getEventUrl(pageUrl, event, getState().multilingual)
  dispatch(shareEventClicked())
  await dispatch(
    openModal({
      type: 'share-event',
      params: {eventUrl, eventId, origin: BI_ORIGINS.MEMBERS_AREA},
    }),
  )
  dispatch(setActiveElement(returnFocusElement))
}

export const seeOtherEvents = () => ({type: SEE_OTHER_EVENTS})

export const shareEventClicked = () => ({type: SHARE_EVENT_CLICKED})
